// Layout of project

// Container-devices
$container-xl: 1440px;
$container-l: 1216px;
$container-m: 960px;
$container-s: 620px;
$container-xs: 340px;
$gutter: 32px;

// Mixin of media-queries
@mixin responsive($device) {


  @if $device == xl {
    // For resolutions upper 1440px
    @media screen and (min-width: $container-xl) {
      @content;
    }
  } 

  @else if $device == ul {
    // For resolutions upper 960px
    @media screen and (min-width: $container-m) {
      @content;
    }
  } 
  
  @else if $device == l {
    // For resolutions between 1140px and 1440px
    @media screen and (max-width: $container-xl) and (min-width: $container-l) {
      @content;
    }
  } 
  
  
  @else if $device == m {
    // For resolutions between 960px and 1140px
    @media screen and (max-width: $container-l) and (min-width: $container-s) {
      @content;
    }
  } 
  
  
  @else if $device == s {
    // For resolutions less than 960px
    @media screen and (max-width: $container-m) {
      @content;
    }
  } 
  
  
  @else if $device == xs {
    // For resolutions less than 340px
    @media screen and (max-width: $container-xs) {
      @content;
    }
  }
}


@function grid($col, $total: 12){
  @return ($col / $total) * 100%;
}


@for $i from 1 through 12{
  .grid-#{$i}{
      width: calc(#{grid($i)} - #{$gutter});
      margin: $gutter / 2;

      padding: 16px 0px;
      float: left;

      @include responsive(s){
          width: calc(100% - #{$gutter});
          float: none;
      }
  }
}

@for $i from 1 through 12{
  .grid-nobreak-#{$i}{
      width: calc(#{grid($i)} - #{$gutter});
      margin: $gutter / 2;

      padding: 16px 0px;
      float: left;
  }
}



.container {
  max-width: $container-l;
  margin: 0 auto;

  padding: 128px 24px;

  /*display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;*/
  overflow: hidden;
  //position: sticky;

  // clearfix
  &::after{
    content: '';
    display: table;
    clear: both;
  }

  @include responsive(s) {
    flex-direction: column;

    padding: 96px 16px;
  }
}

.hero{
  height: 70vh;
}

.disappear{
  @include responsive(s){
    display: none;
  }
}


.row{
  // clearfix
  &::after{
    content: '';
    display: table;
    clear: both;
  }
}

.h-100{
  height: 100vh;
}

.h-100p{
  height: 100%;
}


.flex {
  display: flex;
}

.flex-start-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

}
.flex-end-row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;

}
.flex-start-column {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}


.flex-space {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  @include responsive(s){
    //flex-direction: column;
    //align-items: flex-start;
  }
}

.flex-column {
  flex-direction: column;
}


.relative {
  position: relative;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-item-1 {
  flex: 1 1 360px;
}

.flex-item-2 {
  flex: 2 1 360px;
}

.flex-item-3 {
  flex: 3 1 360px;
}

.flex-item-4 {
  flex: 4 1 360px;
}

.flex-item-5 {
  flex: 5 1 360px;
}

.flex-auto {
  flex: 1 1 auto;
}

// Width's
.w-auto {
  width: auto;
}
.h-auto {
  height: auto !important;
}
.w-100 {
  width: 100%;
}
.w-75 {
  width: 75%;
}
.w-50 {
  width: 50%;
}
.w-25 {
  width: 25%;
}


.bb-black{
  border-bottom: 1px solid $black-light;
}

.bt-black{
  border-top: 1px solid $black-light;
}

.bl-black{
  border-left: 1px solid $black-light;
}

.br-black{
  border-right: 1px solid $black-light;
}


.bg-gray{
  background-color: $black-normal;
}

.bb-gray{
  border-bottom: 2px solid $black-normal;
}

.bt-gray{
  border-top: 2px solid $black-normal;
}

.bl-gray{
  border-left: 2px solid $black-normal;
}

.br-gray{
  border-right: 2px solid $black-normal;
}


.profile{
  width: 48px;
  height: 48px;

  border-radius: 50%;
}

.profile-big{
  width: 128px;
  height: 128px;

  border-radius: 50%;
}

.profile-img{
  border-radius: 50%;
  border: 2px solid $primary-normal;
}

.card{
  border: 1px solid $black-light;
  border-radius: 4px;
  background-color: rgba(15, 20, 31, 0);

  transition: .2s ease;

  &:hover{
    background-color: rgba(15, 20, 31, 0.2);
  }
}

.thumb{

  img{
    transform: scale(1.05);
    transition: .5s ease;
  }
  
  &:hover{
    img{
      transform: scale(1.1);
    }
  }
}

.img-banner{
  width: 100%;
  max-height: 420px;

  background-color: $black-normal;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  img{
    object-fit: cover;
    
    transition: .7s ease;
  }

  &:hover{
    img{
      transform: scale(1.025);
    }
  }
}

.bg-section{
  background-color: rgba(15, 20, 31, 0.5);
}

.card-danger{
  border: 2px solid $red-normal;
  border-radius: 4px;

  background-color: rgba(240, 61, 61, 0.2);
}

.card-success{
  border: 2px solid $green-normal;
  border-radius: 4px;

  background-color: rgba(11, 176, 123, 0.2);
}

.card-warning{
  border: 2px solid $yellow-normal;
  border-radius: 4px;

  background-color: rgba(255, 206, 82, 0.2);
}

.card-info{
  border: 2px solid $primary-normal;
  border-radius: 4px;

  background-color: rgba(27, 105, 210, 0.2);
}