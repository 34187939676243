// Colors of project

// Brand-colors
$primary-normal: #1B69D2;
$primary-dark: #1357B3;
$primary-light: #5390E3;

$secondary-normal: #1B69D2;
$secondary-dark: #1357B3;
$secondary-light: #5390E3;

// Neutral-colors
$black-normal: #0F141F;
$black-dark: #171111;
$black-light: #151B26;
$black-darken: #0F141F;

$white-normal: #E8EBF0;
$white-dark: #D8DBE3;
$white-light: #F7F9FC;

// Gray Scale
$gray-1: #131414;
$gray-2: #2B2B2C;
$gray-3: #424344;
$gray-4: #595A5C;
$gray-5: #717274;
$gray-6: #888A8C;
$gray-7: #9EA0A3;


// Auxiliary colors
$red-normal: #F03D3D;
$red-dark: #d03333;
$red-light: #f05454;

$green-normal: #0BB07B;
$green-dark: #28bb49;
$green-light: #50e170;

$yellow-normal: #FFCE52;
$yellow-dark: #d9b043;
$yellow-light: #ffd76b;

.color-white{
    color: $white-normal;
}

.color-primary{
    color: $primary-light;
}

.color-gradient{
    background: linear-gradient(224.78deg, #E35353 8.12%, #B31313 92.21%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.color-gray{
    color: $gray-5;
}

.color-red{
    color: $red-normal;
}

.color-green{
    color: $green-normal;
}

.color-yellow{
    color: $yellow-normal;
}
