@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500&display=swap");
* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.color-white {
  color: #E8EBF0;
}

.color-primary {
  color: #E35353;
}

.color-gradient {
  background: linear-gradient(224.78deg, #E35353 8.12%, #B31313 92.21%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.color-gray {
  color: #717274;
}

.color-red {
  color: #F03D3D;
}

.color-green {
  color: #0BB07B;
}

.color-yellow {
  color: #FFCE52;
}

.tab-content {
  display: none;
}

.tab-content.active {
  display: block;
}



.grid-1 {
  width: calc(8.33333% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

@media screen and (max-width: 960px) {
  .grid-1 {
    width: calc(100% - 32px);
    float: none;
  }
}

.grid-2 {
  width: calc(16.66667% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

@media screen and (max-width: 960px) {
  .grid-2 {
    width: calc(100% - 32px);
    float: none;
  }
}

.grid-3 {
  width: calc(25% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

@media screen and (max-width: 960px) {
  .grid-3 {
    width: calc(100% - 32px);
    float: none;
  }
}

.grid-4 {
  width: calc(33.33333% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}
.grid-4-prod {
  height: 100%;
}
.grid-4-svc {
  width: calc(33.33333% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}
.grid-4-ctt {
  width: calc(33.33333% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

@media screen and (max-width: 960px) {
  .grid-4 {
    width: calc(100% - 32px);
    float: none;
  }
  .grid-4 {
    width: calc(83.33333% - 32px);
    float: none;
  }
  .grid-4-ctt {
    width: calc(53.33333% - 0px);
    margin: 4px;
    padding: 0px 0px 16px 0px;
    float: left;
  }
}

.grid-5 {
  width: calc(41.66667% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

@media screen and (max-width: 960px) {
  .grid-5 {
    width: calc(100% - 32px);
    float: none;
  }
}

.grid-6 {
  width: calc(50% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

@media screen and (max-width: 960px) {
  .grid-6 {
    width: calc(100% - 32px);
    float: none;
  }
}

.grid-7 {
  width: calc(58.33333% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

@media screen and (max-width: 960px) {
  .grid-7 {
    width: calc(100% - 32px);
    float: none;
  }
}

.grid-8 {
  width: calc(66.66667% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

@media screen and (max-width: 960px) {
  .grid-8 {
    width: calc(100% - 32px);
    float: none;
  }
}

.grid-9 {
  width: calc(75% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

@media screen and (max-width: 960px) {
  .grid-9 {
    width: calc(100% - 32px);
    float: none;
  }
}

.grid-10 {
  width: calc(83.33333% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

@media screen and (max-width: 960px) {
  .grid-10 {
    width: calc(100% - 32px);
    float: none;
  }
}

.grid-11 {
  width: calc(91.66667% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

@media screen and (max-width: 960px) {
  .grid-11 {
    width: calc(100% - 32px);
    float: none;
  }
}

.grid-12 {
  width: calc(100% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}
.grid-12-detailed-prod {
  width: calc(100% - 32px);
  margin: 16px;
  padding: 16px 0px;
}
.detailed-prod {
  display: flex;
  align-items: center;
  justify-content: center;
}


@media screen and (max-width: 960px) {
  .grid-12 {
    width: calc(100% - 32px);
    float: none;
  }
}

.grid-nobreak-1 {
  width: calc(8.33333% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

.grid-nobreak-2 {
  width: calc(16.66667% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

.grid-nobreak-3 {
  width: calc(25% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

.grid-nobreak-4 {
  width: calc(33.33333% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

.grid-nobreak-5 {
  width: calc(41.66667% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

.grid-nobreak-6 {
  width: calc(50% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

.grid-nobreak-7 {
  width: calc(58.33333% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

.grid-nobreak-8 {
  width: calc(66.66667% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

.grid-nobreak-9 {
  width: calc(75% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

.grid-nobreak-10 {
  width: calc(83.33333% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

.grid-nobreak-11 {
  width: calc(91.66667% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

.grid-nobreak-12 {
  width: calc(100% - 32px);
  margin: 16px;
  padding: 16px 0px;
  float: left;
}

.container-hero {
  max-width: 1216px;
  margin: 0 auto;
  padding: 48px 0px;
  /*display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;*/
  overflow: hidden;
  position: relative;
  text-align: center;
}

.container-svc-main {
  margin: 0 auto;
  padding: -60px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
}
.container-svc {
  max-width: 1216px;
  margin: 0 auto;
  padding: 128px 0px;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
}

.container-detailed-prod {
  margin: 0 auto;
  padding: -60px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
}


.container-enviar {
  max-width: 1216px;
  margin: 0 auto;
  padding: 0px 0px 128px 0px;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
}

.container-contact {
  max-width: 1216px;
  margin: 0 auto;
  padding: 0px 0px;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
}

.container-about {
  max-width: 1216px;
  margin: 0 auto;
  padding: 128px 0px 16px 0px;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
}
.container-about-2 {
  max-width: 1216px;
  margin: 0 auto;
  padding: 0px 0px 16px 0px;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: hidden;
}

.container-prod {
  max-width: 1216px;
  margin: 0 auto;
  padding: 0px 0px 0px 0px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.container {
  max-width: 1216px;
  margin: 0 auto;
  padding: 128px 0px;
  /*display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;*/
  overflow: hidden;
}
.container-post {
  max-width: 1216px;
  margin: 0 auto;
  padding: 128px 0px;
  /*display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;*/
  overflow: hidden;
}
.container-products-page {
  max-width: 1216px;
  margin: 0 auto;
  padding: 128px 0px;
  /*display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;*/
  overflow: hidden;
}
.container-contact{
  max-width: 1216px;
  margin: 0 auto;
  padding: 128px 0px;
  /*display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;*/
  overflow: hidden;
}
.container-profile{
  max-width: 1216px;
  margin: 0 auto;
  padding: 128px 0px;
  /*display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;*/
  overflow: hidden;
}

.container-login {
  max-width: 1216px;
  margin: 0 auto;
  padding: 128px 0px;
  /*display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;*/
  overflow: hidden;
}

.container-hero::after {
  content: '';
  display: table;
  clear: both;
}
.container-prod::after {
  content: '';
  display: table;
  clear: both;
}
.container-svc::after {
  content: '';
  display: table;
  clear: both;
}
.container-svc-main::after {
  content: '';
  display: table;
  clear: both;
}

.container::after {
  content: '';
  display: table;
  clear: both;
}

@media screen and (max-width: 960px) {
  .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 24px 0px;
  }
  .container-login {
    flex-direction: column;
    padding: 124px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .container-svc-main {
    flex-direction: column;
    overflow: hidden;
    margin-left: 0;
  }
  .container-detailed-prod {
    flex-direction: column;
  }

  .container-contact {
    flex-direction: column;
  }
  .grid-4-svc {
    padding: 16px 50px;
  }
}
@media screen and (min-width: 960px) {
  .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 24px 0px;
  }
  .container-svc-main {
    flex-direction: row;
    overflow: hidden;
  }
  .row-svc {
    max-width: 259px;
  }
  .row-svc-second {
    max-width: 259px;
    margin-left: 96px;
  }
}


@media screen and (max-width: 960px) {
  .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 128px 0px;
  }
  .container-hero {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 48px 0px;
  }
  .img-hero {
    max-width: 1216px;
    max-height: 710px;
  }
  .second {
    display: none;
  }
}

.hero {
  height: 70vh;
}

@media screen and (max-width: 960px) {
  .disappear {
    display: none;
  }
}

.whatsapp-link {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d36d;
  color: #fff;
  border-radius: 90px;
  text-align: center;
  font-size: 40px;
  box-shadow: 2px 2px 3px #252525;
  z-index: 1000;
}

.shadow {
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.fa {
  margin-top: 9px;
  margin-left: 9px;
}


.row::after {
  content: '';
  display: table;
  clear: both;
}

.h-100 {
  height: 100vh;
}

.h-100p {
  height: 100%;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.row-blog {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media screen and (max-width: 960px) {
  .row-blog {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}


.flex-start-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.flex-end-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.flex-start-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.flex-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
flex-center-prod {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}


.flex-center-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.flex-space {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.align-items-center {
  text-align: center;
}

.relative {
  position: relative;
}

.flex-wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.flex-item-1 {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 360px;
          flex: 1 1 360px;
}

.flex-item-2 {
  -webkit-box-flex: 2;
      -ms-flex: 2 1 360px;
          flex: 2 1 360px;
}

.flex-item-3 {
  -webkit-box-flex: 3;
      -ms-flex: 3 1 360px;
          flex: 3 1 360px;
}

.flex-item-4 {
  -webkit-box-flex: 4;
      -ms-flex: 4 1 360px;
          flex: 4 1 360px;
}

.flex-item-5 {
  -webkit-box-flex: 5;
      -ms-flex: 5 1 360px;
          flex: 5 1 360px;
}

.flex-auto {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

.w-auto {
  width: auto;
}

.h-auto {
  height: auto !important;
}

.w-100 {
  width: 100%;
}

.w-75 {
  width: 75%;
}

.w-50 {
  width: 50%;
}

.w-25 {
  width: 25%;
}

.bb-black {
  border-bottom: 1px solid #3b1f1f;
}

.bt-black {
  border-top: 1px solid #3b1f1f;
}

.bl-black {
  border-left: 1px solid #3b1f1f;
}

.br-black {
  border-right: 1px solid #3b1f1f;
}

.bg-gray {
  background-color: #1f0f0f;
}

.bb-gray {
  border-bottom: 2px solid #1f0f0f;
}

.bt-gray {
  border-top: 2px solid #1f0f0f;
}

.bl-gray {
  border-left: 2px solid #1f0f0f;
}

.br-gray {
  border-right: 2px solid #1f0f0f;
}

.profile {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.profile-big {
  width: 128px;
  height: 128px;
  border-radius: 50%;
}

.profile-img {
  border-radius: 50%;
  border: 2px solid #d21b1b;
}

.card {
  border: 1px solid #5e2828;
  border-radius: 4px;
  background-color: rgba(31, 15, 15, 0);
  -webkit-transition: .2s ease;
  transition: .2s ease;
}

.card:hover {
  background-color: rgba(70, 13, 13, 0.2);
}

.thumb img {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.thumb:hover img {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.img-banner {
  width: 100%;
  max-height: 420px;
  background-color: #1f0f0f;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.img-banner img {
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transition: .7s ease;
  transition: .7s ease;
}

.img-banner:hover img {
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
}

.bg-section {
  background-color: rgba(39, 22, 22, 0.5);
  padding: 48px 0px;
}

.card-danger {
  border: 2px solid #F03D3D;
  border-radius: 4px;
  background-color: rgba(240, 61, 61, 0.2);
}

.card-success {
  border: 2px solid #0BB07B;
  border-radius: 4px;
  background-color: rgba(11, 176, 123, 0.2);
}

.card-warning {
  border: 2px solid #FFCE52;
  border-radius: 4px;
  background-color: rgba(255, 206, 82, 0.2);
}

.card-info {
  border: 2px solid #d21b1b;
  border-radius: 4px;
  background-color: rgba(210, 27, 27, 0.2);
}

.s-0 {
  margin: 0;
  padding: 0;
}

.mt-110 {
  margin-top: 110px;
}

@media screen and (max-width: 960px) {
  .mt-110 {
    margin-top: 0px;
  }
}

.mt-80 {
  margin-top: 88px;
}

@media screen and (max-width: 960px) {
  .mt-80 {
    margin-top: 64px;
  }
}

.ml-40 {
  margin-left: 40px;
}

@media screen and (max-width: 960px) {
  .ml-40 {
    margin-left: 4px;
  }
}

.space-220 {
  padding: 220px 24px;
}

@media screen and (max-width: 960px) {
  .space-220 {
    padding: 96px 16px;
  }
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 8px !important;
}

.m-2 {
  margin: 16px !important;
}

.m-3 {
  margin: 24px !important;
}

.m-4 {
  margin: 32px !important;
}

.m-5 {
  margin: 48px !important;
}

.m-6 {
  margin: 64px !important;
}

.m-7 {
  margin: 72px !important;
}

.m-8 {
  margin: 96px !important;
}

.m-9 {
  margin: 128px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 8px !important;
}

.mt-2 {
  margin-top: 16px !important;
}

.mt-3 {
  margin-top: 24px !important;
}

.mt-4 {
  margin-top: 32px !important;
}

.mt-5 {
  margin-top: 48px !important;
}

.mt-6 {
  margin-top: 64px !important;
}

.mt-7 {
  margin-top: 72px !important;
}

.mt-8 {
  margin-top: 96px !important;
}

.mt-9 {
  margin-top: 128px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: 8px !important;
}

.ml-2 {
  margin-left: 16px !important;
}

.ml-3 {
  margin-left: 24px !important;
}

.ml-4 {
  margin-left: 32px !important;
}

.ml-5 {
  margin-left: 48px !important;
}

.ml-6 {
  margin-left: 64px !important;
}

.ml-7 {
  margin-left: 72px !important;
}

.ml-8 {
  margin-left: 96px !important;
}

.ml-9 {
  margin-left: 128px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: 8px !important;
}

.mr-2 {
  margin-right: 16px !important;
}

.mr-3 {
  margin-right: 24px !important;
}

.mr-4 {
  margin-right: 32px !important;
}

.mr-5 {
  margin-right: 48px !important;
}

.mr-6 {
  margin-right: 64px !important;
}

.mr-7 {
  margin-right: 72px !important;
}

.mr-8 {
  margin-right: 96px !important;
}

.mr-9 {
  margin-right: 128px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 8px !important;
}

.mb-2 {
  margin-bottom: 16px !important;
}

.mb-3 {
  margin-bottom: 24px !important;
}

.mb-4 {
  margin-bottom: 32px !important;
}

.mb-5 {
  margin-bottom: 48px !important;
}

.mb-6 {
  margin-bottom: 64px !important;
}

.mb-7 {
  margin-bottom: 72px !important;
}

.mb-8 {
  margin-bottom: 96px !important;
}

.mb-9 {
  margin-bottom: 128px !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.mx-2 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.mx-3 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.mx-4 {
  margin-left: 32px !important;
  margin-right: 32px !important;
}

.mx-5 {
  margin-left: 48px !important;
  margin-right: 48px !important;
}

.mx-6 {
  margin-left: 64px !important;
  margin-right: 64px !important;
}

.mx-7 {
  margin-left: 72px !important;
  margin-right: 72px !important;
}

.mx-8 {
  margin-left: 96px !important;
  margin-right: 96px !important;
}

.mx-9 {
  margin-left: 128px !important;
  margin-right: 128px !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.my-2 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.my-3 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.my-4 {
  margin-top: 32px !important;
  margin-bottom: 32px !important;
}

.my-5 {
  margin-top: 48px !important;
  margin-bottom: 48px !important;
}

.my-6 {
  margin-top: 64px !important;
  margin-bottom: 64px !important;
}

.my-7 {
  margin-top: 72px !important;
  margin-bottom: 72px !important;
}

.my-8 {
  margin-top: 96px !important;
  margin-bottom: 96px !important;
}

.my-9 {
  margin-top: 128px !important;
  margin-bottom: 128px !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 8px !important;
}

.p-2 {
  padding: 16px !important;
}

.p-3 {
  padding: 24px !important;
}

.p-4 {
  padding: 32px !important;
}

.p-5 {
  padding: 48px !important;
}

.p-6 {
  padding: 64px !important;
}

.p-7 {
  padding: 72px !important;
}

.p-8 {
  padding: 96px !important;
}

.p-9 {
  padding: 128px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 8px !important;
}

.pt-2 {
  padding-top: 16px !important;
}

.pt-3 {
  padding-top: 24px !important;
}

.pt-4 {
  padding-top: 32px !important;
}

.pt-5 {
  padding-top: 48px !important;
}

.pt-6 {
  padding-top: 64px !important;
}

.pt-7 {
  padding-top: 72px !important;
}

.pt-8 {
  padding-top: 96px !important;
}

.pt-9 {
  padding-top: 128px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 8px !important;
}

.pl-2 {
  padding-left: 16px !important;
}

.pl-3 {
  padding-left: 24px !important;
}

.pl-4 {
  padding-left: 32px !important;
}

.pl-5 {
  padding-left: 48px !important;
}

.pl-6 {
  padding-left: 64px !important;
}

.pl-7 {
  padding-left: 72px !important;
}

.pl-8 {
  padding-left: 96px !important;
}

.pl-9 {
  padding-left: 128px !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-1 {
  padding-right: 8px !important;
}

.pr-2 {
  padding-right: 16px !important;
}

.pr-3 {
  padding-right: 24px !important;
}

.pr-4 {
  padding-right: 32px !important;
}

.pr-5 {
  padding-right: 48px !important;
}

.pr-6 {
  padding-right: 64px !important;
}

.pr-7 {
  padding-right: 72px !important;
}

.pr-8 {
  padding-right: 96px !important;
}

.pr-9 {
  padding-right: 128px !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 8px !important;
}

.pb-2 {
  padding-bottom: 16px !important;
}

.pb-3 {
  padding-bottom: 24px !important;
}

.pb-4 {
  padding-bottom: 32px !important;
}

.pb-5 {
  padding-bottom: 48px !important;
}

.pb-6 {
  padding-bottom: 64px !important;
}

.pb-7 {
  padding-bottom: 72px !important;
}

.pb-8 {
  padding-bottom: 96px !important;
}

.pb-9 {
  padding-bottom: 128px !important;
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.px-2 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.px-3 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.px-4 {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.px-5 {
  padding-left: 48px !important;
  padding-right: 48px !important;
}

.px-6 {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.px-7 {
  padding-left: 72px !important;
  padding-right: 72px !important;
}

.px-8 {
  padding-left: 96px !important;
  padding-right: 96px !important;
}

.px-9 {
  padding-left: 128px !important;
  padding-right: 128px !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-2 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-3 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.py-4 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.py-5 {
  padding-top: 48px !important;
  padding-bottom: 48px !important;
}

.py-6 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.py-7 {
  padding-top: 72px !important;
  padding-bottom: 72px !important;
}

.py-8 {
  padding-top: 96px !important;
  padding-bottom: 96px !important;
}

.py-9 {
  padding-top: 128px !important;
  padding-bottom: 128px !important;
}

.h0 {
  font-family: 'Rubik', sans-serif;
  font-size: 120px;
  line-height: 120%;
  font-weight: 500;
}
.h0-hero {
  font-family: 'Rubik', sans-serif;
  font-size: 96px;
  line-height: 120%;
  font-weight: 500;
  position: absolute;
  transform: translate(0%, -130%);
}

/*PRODUCTS*/

.prod-img {
  min-height: 31px;
  max-width: 31px;  
}

.products-box{
  display: flex;
  align-items: flex-end;
  min-height: 415px;
}
.container .row .grid-6:nth-child(1) .products-box {
  background-image: url(../img/portfolio-astoria/maquina-profissional-astoria-modelo-avant.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 52%;
  background-position-y:-5%;
}
.container .row .grid-6:nth-child(2) .products-box {
  background-image: url(../img/portfolio-jura/page\ 1_5.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 43%;
  background-position-y:0%;
}
.container .row .grid-6:nth-child(3) .products-box {
  background-image: url(../img/maquinas-coffee/SVG/gvh3ainmetro.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 44%;
  background-position-y: 0%;
}
.container .row .grid-6:nth-child(4) .products-box {
  background-image: url(../img/maquinas-coffee/SVG/h3eaelementinmetro.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 48%;
  background-position-y:-2%;
}

@media screen and (max-width: 960px) {
  .btn-prod {
    font-family: 'Rubik', sans-serif;
    font-size: 4vw;
    line-height: 6vw;
    font-weight: 200;
    padding: 16px 24px;
  }
  .container .row .grid-6:nth-child(1) .products-box {
    background-image: url(../img/portfolio-astoria/maquina-profissional-astoria-modelo-avant.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
    background-position-y:-10%;
  }
  .container .row .grid-6:nth-child(2) .products-box {
    background-image: url(../img/portfolio-jura/page\ 1_5.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
    background-position-y:0%;
  }
  .container .row .grid-6:nth-child(3) .products-box {
    background-image: url(../img/maquinas-coffee/SVG/gvh3ainmetro.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 75%;
    background-position-y: 10%;
  }
  .container .row .grid-6:nth-child(4) .products-box {
    background-image: url(../img/maquinas-coffee/SVG/h3eaelementinmetro.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 70%;
    background-position-y:5%;
  }
}


.products-name{
  padding: 4vh 4vh;
  background-color:#261515;
  min-width: 100%;
}
.br-pd {
  border-radius: 6px;
  border: 0;
}

.more-products {
  display: flex;
  justify-content: center;
  transform: translate(0%, 60%);
}

.spacing-btn {
  padding-top: 16px;
}
.btn-prod {
  display: inline-block;
  color: #F7F9FC;
  -webkit-transition: .6s ease;
  transition: .6s ease;
  padding: 8px 24px;
  text-align: center;
  border: 0;
  background: linear-gradient(224.78deg, #E35353 8.12%, #B31313 92.21%);
  border-radius: 4px;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 200;
  position: relative;
}

@media screen and (max-width: 960px) {
  .btn-prod {
    font-family: 'Rubik', sans-serif;
    font-size: 4vw;
    line-height: 6vw;
    font-weight: 200;
    padding: 16px 24px;
  }

}

@media screen and (max-width: 340px) {
  .btn-prod {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    line-height: 26px;
    font-weight: 200;
    padding: 16px 24px;
  }

}

.btn-prod:hover {
  opacity: .8;
}


/*SERVICES*/

.svc-img {
  min-height: 24px;
  max-width: 24px;
}

.svc-main-img {
  min-height: 92px;
  min-width: 92px;
  max-width: 92px;
  max-height: 92px;
}

.svc-desc {
  text-align: center;
  min-width: 259px;
  padding-top: 8px;
}

@media screen and (max-width: 960px) {
  .svc-main-img {
    max-width: 92px;
    max-height: 92px;
  }
}


/*ABOUT US*/

.about-img {
  min-height: 31px;
  max-width: 31px;
}
.about-name{
  padding: 16px;
}
.about-desc {
  text-align: center;
  max-width: 197px;
}

.banner-about {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.banner-about-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.img-banner-about {
  width: 1010px;
  height: 261px;
}
.img-banner-about-class {
  width: 1010px;
  height: 261px;
}

.about-person{
  max-width: 136px;
  max-height: 130px;
}
.about-grid {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about-story {
  padding: 0px 30px;
}
@media screen and (max-width: 960px) {
  .banner-about-2 {
    flex-direction: column;
  }
  .about-story {
    text-align: center;
  }
  .about-desc {
    text-align: center;
    max-width: 400px;
  }
}

/* CONTACT US */

.flex-center-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media screen and (max-width: 960px) {
  .flex-center-contact {
    flex-direction: column;
  }
  .flex-center-prod {
    flex-direction: column;
  }
}

@media screen and (max-width: 960px) {
  .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 24px 24px;
  }
}




@media screen and (max-width: 960px) {
  .h0 {
    font-family: 'Rubik', sans-serif;
    font-size: 88px;
    line-height: 120%;
    font-weight: 500;
  }
  .h0-hero {
    font-family: 'Rubik', sans-serif;
    font-size: 12vw;
    line-height: 120%;
    font-weight: 300;
    position: absolute;
    transform: translate(0%, -100%);
  }
}

@media screen and (max-width: 340px) {
  .h0 {
    font-family: 'Rubik', sans-serif;
    font-size: 8vw;
    line-height: 120%;
    font-weight: 500;
  }
  .h0-hero {
    font-family: 'Rubik', sans-serif;
    font-size: 8vw;
    line-height: 120%;
    font-weight: 300;
    position: absolute;
    transform: translate(0%, -130%);
  }
}

h1 {
  color: #D8DBE3;
  z-index: 5;
  font-family: 'Rubik', sans-serif;
  font-size: 80px;
  line-height: 100%;
  font-weight: 500;
}

@media screen and (max-width: 960px) {
  h1 {
    font-family: 'Rubik', sans-serif;
    font-size: 56px;
    line-height: 110%;
    font-weight: 500;
  }
}

@media screen and (max-width: 340px) {
  h1 {
    font-family: 'Rubik', sans-serif;
    font-size: 48px;
    line-height: 120%;
    font-weight: 500;
  }
}

h2 {
  color: #D8DBE3;
  font-family: 'Rubik', sans-serif;
  font-size: 64px;
  line-height: 110%;
  font-weight: 300;
}

@media screen and (max-width: 960px) {
  h2 {
    font-family: 'Rubik', sans-serif;
    font-size: 40px;
    line-height: 120%;
    font-weight: 300;
  }
}

@media screen and (max-width: 340px) {
  h2 {
    font-family: 'Rubik', sans-serif;
    font-size: 36px;
    line-height: 120%;
    font-weight: 300;
  }
}

h3 {
  color: #D8DBE3;
  font-family: 'Rubik', sans-serif;
  font-size: 48px;
  line-height: 120%;
  font-weight: 300;
}

@media screen and (max-width: 960px) {
  h3 {
    font-family: 'Rubik', sans-serif;
    font-size: 32px;
    line-height: 120%;
    font-weight: 300;
  }
}

@media screen and (max-width: 340px) {
  h3 {
    font-family: 'Rubik', sans-serif;
    font-size: 28px;
    line-height: 130%;
    font-weight: 300;
  }
}

h4 {
  color: #D8DBE3;
  font-family: 'Rubik', sans-serif;
  font-size: 36px;
  line-height: 120%;
  font-weight: 300;
}

@media screen and (max-width: 960px) {
  h4 {
    font-family: 'Rubik', sans-serif;
    font-size: 28px;
    line-height: 130%;
    font-weight: 300;
  }
}

@media screen and (max-width: 340px) {
  h4 {
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    line-height: 140%;
    font-weight: 300;
  }
}

h5 {
  color: #D8DBE3;
  font-family: 'Rubik', sans-serif;
  font-size: 24px;
  line-height: 140%;
  font-weight: 300;
}

@media screen and (max-width: 960px) {
  h5 {
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    line-height: 140%;
    font-weight: 300;
  }
}

h6 {
  color: #717274;
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  line-height: 150%;
  font-weight: 100;
}
h6-about-story {
  color:#D8DBE3;
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  line-height: 150%;
  font-weight: 100;
}
h6-desc {
  color: #E35353;
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  line-height: 150%;
  font-weight: 100;
}

@media screen and (max-width: 960px) {
  h6 {
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    line-height: 150%;
    font-weight: 100;
  }
}

.h7 {
  font-family: 'Rubik', sans-serif;
  font-size: 14px;
  line-height: 150%;
  font-weight: 100;
}

@media screen and (max-width: 960px) {
  .h7 {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    line-height: 150%;
    font-weight: 100;
  }
}

p {
  letter-spacing: 0px;
  color: #717274;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 200;

}
.p-hero {
  letter-spacing: 0px;
  color: #999a9b;
  font-family: 'Rubik', sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-weight: 200;
  transform: translate(0%, -160%);
  position: absolute;
  word-wrap: break-word;
}


@media screen and (max-width: 960px) {
  p {
    font-family: 'Rubik', sans-serif;
    font-size: 24px;
    line-height: 24px;
    font-weight: 200;
  }
  .p-hero {
    font-family: 'Rubik', sans-serif;
    font-size: 4vw;
    line-height: 4vw;
    font-weight: 200;
    position: absolute;
    transform: translate(0%, -50%);
  }
}

@media screen and (max-width: 340px) {
  p {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    line-height: 26px;
    font-weight: 200;
  }
  .p-hero {
    font-family: 'Rubik', sans-serif;
    font-size: 3vw;
    line-height: 4vw;
    font-weight: 200;
    position: absolute;
    transform: translate(0%, -50%);
  }
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 2px;
}

.p1 {
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  line-height: 30px;
  font-weight: 300;
}

@media screen and (max-width: 960px) {
  .p1 {
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    line-height: 30px;
    font-weight: 300;
  }
}

@media screen and (max-width: 340px) {
  .p1 {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
  }
}

.p2 {
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  line-height: 30px;
  font-weight: 200;
}

@media screen and (max-width: 960px) {
  .p2 {
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    line-height: 26px;
    font-weight: 200;
  }
}

.p3 {
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 200;
}

@media screen and (max-width: 960px) {
  .p3 {
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    line-height: 26px;
    font-weight: 200;
  }
}

.p4 {
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 200;
}

@media screen and (max-width: 960px) {
  .p4 {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 200;
  }
}

.p5 {
  font-family: 'Rubik', sans-serif;
  font-size: 13px;
  line-height: 20px;
  font-weight: 200;
}

@media screen and (max-width: 960px) {
  .p5 {
    font-family: 'Rubik', sans-serif;
    font-size: 13px;
    line-height: 20px;
    font-weight: 200;
  }
}

.p-title {
  font-size: 18px;
}

@media screen and (max-width: 960px) {
  .p-title {
    font-size: 16px;
  }
}

.bold {
  font-weight: bold;
  color: #D8DBE3;
}

span {
  position: relative;
  display: inline-block;
  color: #d21b1b;
}

.letter-h1 {
  color: #E8EBF0;
  letter-spacing: 1px;
  text-transform: none;
  font-family: 'Rubik', sans-serif;
  font-size: 80px;
  line-height: 100%;
  font-weight: 500;
}

@media screen and (max-width: 960px) {
  .letter-h1 {
    font-family: 'Rubik', sans-serif;
    font-size: 56px;
    line-height: 110%;
    font-weight: 500;
  }
}

strong {
  color: #171111;
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  line-height: 30px;
  font-weight: 200;
}

@media screen and (max-width: 960px) {
  strong {
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
    line-height: 26px;
    font-weight: 200;
  }
}

b {
  color: #2B2B2C;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.h-xl {
  color: #E8EBF0;
  font-family: 'Rubik', sans-serif;
  font-size: 120px;
  line-height: 120%;
  font-weight: 500;
  font-size: 240px;
}

@media screen and (max-width: 960px) {
  .h-xl {
    font-family: 'Rubik', sans-serif;
    font-size: 88px;
    line-height: 120%;
    font-weight: 500;
    font-size: 144px;
  }
}

.h-l {
  color: #E8EBF0;
  font-family: 'Rubik', sans-serif;
  font-size: 120px;
  line-height: 120%;
  font-weight: 500;
  font-size: 144px;
}

@media screen and (max-width: 960px) {
  .h-l {
    font-family: 'Rubik', sans-serif;
    font-size: 88px;
    line-height: 120%;
    font-weight: 500;
    font-size: 96px;
  }
}

.rounded-img {
  border-radius: 8px;
}

html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  position: relative;
  background-color: #171111;
}

body::-webkit-scrollbar {
  padding-right: 2px;
  width: 8px;
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #ee5d5d;
}

.border {
  border: 1px solid red;
}

.hidden {
  overflow: hidden;
}

.relative {
  position: relative;
}

svg, img {
  width: 100%;
}

header {
  width: 100%;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content:space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(16, 9, 9, 0.4);
  -webkit-backdrop-filter: blur(6px);
          backdrop-filter: blur(6px);
  border-bottom: 1px solid #261515;
  position: fixed;
  z-index: 990;
}

nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.menu {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.menu li {
  margin-left: 24px;
}

@media screen and (max-width: 960px) {
  .menu {
    display: none;
  }
}

@media screen and (max-width: 960px) {
  .search {
    display: none;
  }
}

.bx {
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
  display: none;
  position: relative;
}

@media screen and (max-width: 960px) {
  .bx {
    display: block;
  }
}

.bx::after {
  content: '';
  position: absolute;
  top: 35%;
  left: 40%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 2px;
  width: 70%;
  height: 2px;
  display: block;
  -webkit-transition: .5s ease;
  transition: .5s ease;
  background-color: #D8DBE3;
}

.bx::before {
  content: '';
  position: absolute;
  top: 65%;
  left: 60%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 2px;
  width: 70%;
  height: 2px;
  display: block;
  -webkit-transition: .5s ease;
  transition: .5s ease;
  background-color: #D8DBE3;
}

.bx.activebx {
  position: relative;
}

.bx.activebx::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(135deg);
          transform: translate(-50%, -50%) rotate(135deg);
  border-radius: 2px;
  width: 70%;
  height: 2px;
  display: block;
  background-color: #D8DBE3;
}

.bx.activebx::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-315deg);
          transform: translate(-50%, -50%) rotate(-315deg);
  border-radius: 2px;
  width: 70%;
  height: 2px;
  display: block;
  background-color: #D8DBE3;
}

.cta-mobile {
  display: none;
}

@media screen and (max-width: 960px) {
  .cta-mobile {
    display: block;
  }
}

.cta-desktop {
  display: block;
}

@media screen and (max-width: 960px) {
  .cta-desktop {
    display: none;
  }
}

.logo {
  max-width: 112px;
  min-width: 56px;
}

.menu-mobile {
  width: 100%;
  height: 100vh;
  -webkit-transition: .7s ease;
  transition: .7s ease;
  position: absolute;
  top: 0;
  left: -100%;
  background-color: rgba(16, 9, 9, 0.8);
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  z-index: 900;
}

.menu-mobile .nav-mobile {
  margin-top: 80px;
}

.menu-mobile li {
  border-bottom: 1px solid #261515;
}

.acess {
  color: #E35353;
}

.menu-mobile li .link-menu-mobile {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 24px 16px 24px 16px;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.menu-mobile li .link-menu-mobile {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 24px 16px 24px 16px;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

.menu-mobile li .link-menu-mobile:hover {
  background-color: rgba(31, 15, 15, 0.7);
  padding: 24px 16px 24px 28px;
}

.navtabs {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

/* Product-4 */

.white-gradient{
  text-decoration: underline;
  font-weight: 200;
  font-size: 24px;
  color: #ffd4d4;
}

.product-4-detail-tabs .detail-tabs {
  display: flex;
  gap: 10px; /* Espaçamento entre as guias */
  margin-bottom: 1rem; /* Espaço abaixo do menu de guias */
}

.product-4-detail-tabs .detail-tab {
  padding: 10px 20px;
  border: 1px solid #ccc;
  cursor: pointer;
  background: #fff;
  border-radius: 5px;
  margin-right: 4px;
}

.product-4-detail-tabs .detail-tab.active-tab {
  background-color: #ddd; /* Cor de fundo para a guia ativa */
  border-bottom: 1px solid #fff; /* Esconde a borda inferior para a guia ativa */
  position: relative;
  bottom: -1px; /* Ajuste para a guia ativa parecer "levantada" */
}

.product-4-detail-tabs .detail-tab-content {
  display: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-top: -1px; /* Ajuste para o conteúdo alinhar com as guias */
}

.product-4-detail-tabs .detail-tab-content.active-tab-content {
  display: block;
}




.active {
  color:#E35353;
}

.menu-mobile.showmenu {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transition: .7s ease;
  transition: .7s ease;
}

.icon {
  max-width: 64px;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

@media screen and (max-width: 960px) {
  .icon {
    max-width: 56px;
  }
}

.icon:hover {
  -webkit-transform: translate(0, -4px, 0);
          transform: translate(0, -4px, 0);
}

.icon-s {
  max-width: 36px;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

@media screen and (max-width: 960px) {
  .icon-s {
    max-width: 32px;
  }
}

.icon-s:hover {
  -webkit-transform: translate(0, -4px, 0);
          transform: translate(0, -4px, 0);
}

.icon-l {
  max-width: 120px;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

@media screen and (max-width: 960px) {
  .icon-l {
    max-width: 96px;
  }
}

.icon-xl {
  max-width: 172px;
  -webkit-transition: .5s ease;
  transition: .5s ease;
}

@media screen and (max-width: 960px) {
  .icon-xl {
    max-width: 144px;
  }
}

/* CARROUSEL (CARROSSEL) */

.carrousel {
  overflow: hidden;
  min-width: 1216px;
}

.carr-container {
  display: flex;
  transition: opacity 0.5s ease-in-out;
  transform: translateX(0);
}

.fade-out {
  opacity: 0;
  background-color: #261515;
  transition: opacity 0.5s ease-in-out;
}


.btn {
  display: inline-block;
  color: #F7F9FC;
  -webkit-transition: .6s ease;
  transition: .6s ease;
  padding: 8px 24px;
  text-align: center;
  border: 0;
  background: linear-gradient(224.78deg, #E35353 8.12%, #B31313 92.21%);
  border-radius: 4px;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  line-height: 26px;
}  

.btn-hero {
  display: inline-block;
  color:#E8EBF0;
  transition: .6s ease;
  padding: 1vw 4vw;
  text-align: center;
  border: 0;
  transform: translate(0%, 10%);
  background: linear-gradient(224.78deg, #E35353 8.12%, #B31313 92.21%);
  border-radius: 4px;
  position: absolute; 
}

@media screen and (max-width: 960px) {
  .btn {
    font-family: 'Rubik', sans-serif;
    font-size: 4vw;
    line-height: 6vw;
    font-weight: 200;
    padding: 16px 24px;
  }
  .btn-hero {
    font-family: 'Rubik', sans-serif;
    font-size: 3.5vw;
    line-height: 4vw;
    font-weight: 200;
    padding: 1vw 4vw;
    transform: translate(0%, 150%);
  }
}

@media screen and (max-width: 340px) {
  .btn {
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    line-height: 26px;
    font-weight: 200;
    padding: 16px 24px;
  }
  .btn-hero {
    font-family: 'Rubik', sans-serif;
    font-size: 3.5vw;
    line-height: 4vw;
    font-weight: 200;
    padding: 1vw 4vw;
    transform: translate(0%, 30%);
  }
}

.btn:hover {
  opacity: .8;
}

a {
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 200;
  text-decoration: none;
  color: #F7F9FC;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

@media screen and (max-width: 960px) {
  a {
    font-family: 'Rubik', sans-serif;
    font-size: 32px;
    line-height: 26px;
    font-weight: 200;
    text-decoration: none;
    color: #F7F9FC;
    -webkit-transition: 0.5s ease;
    transition: 0.5s ease;
  }
  .acesso  {
    font-size: 16px;
    padding: 0px 0px 0px 16px;
  }

}



a:hover {
  opacity: 0.8;
}

.link-title {
  text-decoration: none;
  -webkit-transition: .3s ease;
  transition: .3s ease;
}

.link-title:hover {
  text-decoration: underline;
}

.link {
  color: #E35353;
  font-size: 16px;
}

.link-footer {
  padding: 8px 8px 8px 8px;
  -webkit-transition: .7s ease;
  transition: .7s ease;
}

.link-footer:hover {
  padding: 8px 8px 8px 16px;
}

input {
  accent-color: #d21b1b;
}

/*
input[type=text]{
  padding: 8px 12px;
  background-color: $black-light;
  border: 0;
  border-radius: 4px;
  color: $white-light;

  @include typeface-2(p3, desktop);
}
*/

.form-group textarea {
  resize: none;
}

input, select, option, textarea {
  padding: 10px 12px;
  background-color: #261515;
  border: 0;
  border-radius: 4px;
  color: #F7F9FC;
  width: 100%;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 200;
}

input[type=date], select, option {
  height: 44px;
}

.pointer {
  cursor: pointer;
}

/*
select{
  appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}
*/
.btn-search {
  cursor: pointer;
  height: 48px;
  width: 56px;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transform: translate3d(-6px, 0, 0);
          transform: translate3d(-6px, 0, 0);
  background-color: #261515;
  border: 0;
  border-radius: 4px;
}
/*# sourceMappingURL=style.css.map */