.icon{
  max-width: 64px;
  transition: .5s ease;

  @include responsive(s){
    max-width: 56px;
  }

  &:hover{
    transform: translate(0,-4px,0);
  }
}

.icon-s{
  max-width: 36px;
  transition: .5s ease;

  @include responsive(s){
    max-width: 32px;
  }

  &:hover{
    transform: translate(0,-4px,0);
  }
}

.icon-l{
  max-width: 120px;
  transition: .5s ease;

  @include responsive(s){
    max-width: 96px;
  }
}

.icon-xl{
  max-width: 172px;
  transition: .5s ease;

  @include responsive(s){
    max-width: 144px;
  }

}
