*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@import 'sass/colors';
@import 'sass/layout';
@import 'sass/spacing';
@import 'sass/typography';

html{
  scroll-behavior: smooth;
}

body{
  width: 100%;
  position: relative;

  background-color: $black-dark;

  &::-webkit-scrollbar{
    padding-right: 2px;
    width: 8px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb{
    border-radius: 8px;
    background: $black-light;
  }
}

.border{
  border: 1px solid red;
}

.hidden{
  overflow: hidden;
}

.relative{
  position: relative;
}

svg,img{
  width: 100%;
  //height: auto;
}

@import 'sass/menu';
@import 'sass/icons';
@import 'sass/buttons';

